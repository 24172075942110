import React, { useEffect, useReducer, useContext } from 'react';
import { StoreContext } from 'index';
import 'react-datepicker/dist/react-datepicker.css';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Loader } from '../../Shared';
import {
  getLink,
  notify,
  isValidEmail,
  isValidContactNumber,
  getPositionLabels,
  ifPassedIn,
} from '../../../utils/utilities';
import ChangePassword from 'components/Popup/ChangePassword';
import { PlayerInfo, AccountInfo, SocialInfo } from './index';

const StepSelection = ({ setStep, step }) => {
  return (
    <div className="flex flex-col text-left">
      <button
        className="focus:outline-none mt-3 text-left"
        onClick={() => {
          setStep('step', 1);
        }}>
        <small
          className={`${step === 1 ? 'font-sf-semibold' : 'font-sf-regular'
            }   text-left pl-2 text-primary-color text-lg py-2`}>
          {`${step === 1 ? '•' : ''} Player Info`}
        </small>
      </button>

      <button
        className="focus:outline-none mt-3"
        onClick={() => {
          setStep('step', 2);
        }}>
        <small
          className={`${step === 2 ? 'font-sf-semibold' : 'font-sf-regular'
            }   text-left pl-2 text-primary-color text-lg py-2`}>
          {`${step === 2 ? '•' : ''} Team Info`}
        </small>
      </button>

      <button
        className="focus:outline-none mt-3"
        onClick={() => {
          setStep('step', 3);
        }}>
        <small
          className={`${step === 3 ? 'font-sf-semibold' : 'font-sf-regular'
            }   text-left pl-2 text-primary-color text-lg py-2`}>
          {`${step === 3 ? '•' : ''} Social Info`}
        </small>
      </button>
    </div>
  );
};

function UserProfile(props) {
  const store = useContext(StoreContext);

  let user = toJS(store.userStore.currentUser);
  let currentUser = user.data && user.data.attributes;
  let sports = toJS(store.userStore.sports);
  let sportPositions = toJS(store.userStore.sportPositions);

  const initialState = {
    step: 1,
    loader: false,
    submitLoader: false,
    firstName: null,
    lastName: null,
    email: null,
    contact: null,
    dob: null,
    changePassModal: false,
    oldPass: '',
    password: '',
    confirmPass: '',
    invalidPhone: '',
    school: null,
    college: null,
    club: null,
    currentLocation: '',
    currentLocationData: null,
    hometownLocation: '',
    hometownLocationData: null,
    gender: null,
    positionFirst: null,
    positionSecond: null,
    positionThird: null,
    jerseyNumber: null,
    passingClass: null,
    sport: null,
    about: null,
    tiktokLink: null,
    twitterLink: null,
    facebookLink: null,
    instagramLink: null,
    websiteLink: null,
    img: null,
    socialError: '',
    imgFile: null,
    middleSchool: null,
    timeZone: null,
  };

  function reducer(state, action) {
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    store.userStore.setProfilePopup(false);
    if (user.data) store.userStore.getSportPositions(user.data.attributes.sport_id);

    dispatch({ field: 'positionFirst', value: getExistingPosition(0) });
    dispatch({ field: 'positionSecond', value: getExistingPosition(1) });
    dispatch({ field: 'positionThird', value: getExistingPosition(2) });
    dispatch({
      field: 'school',
      value: {
        label: currentUser && currentUser.high_school,
      },
    });
    dispatch({
      field: 'college',
      value: {
        label:
          currentUser && currentUser.college.data && currentUser.college.data.attributes.nickname,
      },
    });
  }, [provider.step]);

  const onChange = (field, value, error) => {
    dispatch({ field: 'socialError', value: error ? true : '' });
    dispatch({ field, value });
  };

  const getExistingPosition = index => {
    if (currentUser && currentUser.position.length > 0 && currentUser.position.length > index)
      return { label: currentUser.position[index], value: currentUser.position[index] };
    else return null;
  };

  const submit = async e => {
    e.preventDefault();

    if (provider.password !== provider.confirmPass) {
      notify('Password does not match!');
      return;
    }

    onChange('loader', true);
    store.userStore
      .updatePassword({
        current_password: provider.oldPass,
        password: provider.password,
        password_confirmation: provider.confirmPass,
      })
      .then(result => {
        onChange('loader', false);

        if (result.data) {
          notify('Password Updated Successfully!');
          onChange('changePassModal', false);
          onChange('oldPass', '');
          onChange('password', '');
          onChange('confirmPass', '');
        }
      });
  };

  const validatePhone = value => {
    onChange('contact', value);
    if (isValidContactNumber(value)) {
      onChange('invalidPhone', false);
    } else onChange('invalidPhone', true);
  };

  const onImageChange = async e => {
    const file = e.target.files[0];
    const image = URL.createObjectURL(file);
    onChange('img', image);
    onChange('imgFile', file);
  };

  const setCollegeId = (passingYear, step, college) => {
    if (ifPassedIn(passingYear)) {
      return step === 2 ? college && college.value : currentUser.college;
    } else {
      onChange('college', null);
      return null;
    }
  };

  const onSubmit = async e => {
    e.preventDefault();
    let {
      positionFirst,
      positionSecond,
      positionThird,
      firstName,
      lastName,
      contact,
      dob,
      currentLocationData,
      email,
      hometownLocationData,
      school,
      college,
      club,
      gender,
      jerseyNumber,
      passingClass,
      about,
      sport,
      websiteLink,
      tiktokLink,
      facebookLink,
      instagramLink,
      imgFile,
      twitterLink,
      step,
      middleSchool,
      timeZone,
    } = provider;

    if (provider.invalidPhone) notify('Invalid phone number.');
    else if (!isValidEmail(provider.email !== null ? provider.email : currentUser.email))
      notify('Invalid email address.');
    else {
      onChange('submitLoader', true);

      let payload = {
        first_name: firstName !== null ? firstName : currentUser.first_name,
        last_name: lastName !== null ? lastName : currentUser.last_name,
        email: email !== null ? email : currentUser.email,
        contact_number: contact !== null ? contact : currentUser.contact_number,
        dob: dob !== null ? dob : currentUser.dob,
        postal_code:
          currentLocationData !== null ? currentLocationData.zip : currentUser.postal_code,
        city: currentLocationData !== null ? currentLocationData.city : currentUser.city,
        state: currentLocationData !== null ? currentLocationData.state : currentUser.state,
        hometown_city:
          hometownLocationData !== null ? hometownLocationData.city : currentUser.hometown_city,
        hometown_state:
          hometownLocationData !== null ? hometownLocationData.state : currentUser.hometown_state,
        hometown_postal_code:
          hometownLocationData !== null
            ? hometownLocationData.zip
            : currentUser.hometown_postal_code,
        high_school: step === 2 ? school && school.label : currentUser.high_school,
        college_id: setCollegeId(passingClass?.value || currentUser?.passing_year, step, college),
        club: club !== null ? club : currentUser.club,
        gender: gender !== null ? gender.value : currentUser.gender,
        jersey_number: jerseyNumber !== null ? jerseyNumber : currentUser.jersey_number,
        position:
          positionFirst || positionSecond || positionThird ? getPositions() : currentUser.position,
        passing_year: passingClass !== null ? passingClass.value : currentUser.passing_year,
        about: about !== null ? about : currentUser.about,
        sport_id: sport !== null ? sport.id : currentUser.sport_id,
        website: websiteLink !== null ? getLink(websiteLink) : currentUser.website,
        tiktok: tiktokLink !== null ? tiktokLink : currentUser.tiktok,
        facebook: facebookLink !== null ? facebookLink : currentUser.facebook,
        instagram: instagramLink !== null ? instagramLink : currentUser.instagram,
        twitter: twitterLink !== null ? twitterLink : currentUser.twitter,
        middle_school: middleSchool,
        time_zone: timeZone ? timeZone : currentUser.time_zone,
      };

      if (imgFile) {
        let data = await store.userStore.getProfileImageSignedUrl();
        await store.userStore.uploadImage(imgFile, data.signed_url);
        await store.userStore.updateProfile({ profile_image: data.url });
      }

      store.userStore.updateProfile(payload).then(result => {
        if (result.data) {
          notify('Profile Updated Successfully');
        }
        onChange('submitLoader', false);
      });
    }
  };

  const getSchoolsBySearch = async search => {
    let data = await store.userStore.getSchoolsBySearch({ q: { nickname_or_city_start: search } });
    return data;
  };

  const getCollegesBySearch = async search => {
    let data = await store.userStore.getCollegesBySearch({ q: { nickname_or_city_start: search } });
    return data;
  };

  const getPositions = () => {
    let { positionFirst, positionSecond, positionThird } = provider;
    let positions = [];
    if (positionFirst !== null) positions = [...positions, positionFirst.value];
    if (positionSecond !== null) positions = [...positions, positionSecond.value];
    if (positionThird !== null) positions = [...positions, positionThird.value];

    const uniquePositions = [...new Set(positions)];
    return uniquePositions;
  };

  const getSportPositions = async id => {
    await store.userStore.getSportPositions(id);
  };

  const resetPositions = () => {
    dispatch({ field: 'positionFirst', value: null });
    dispatch({ field: 'positionSecond', value: null });
    dispatch({ field: 'positionThird', value: null });
  };

  return (
    <div className="flex flex-wrap w-full bg-main-background min-h-screen p-5 pt-24">
      <div className="flex flex-col w-full md:ml-10 md:w-1/4 items-center md:items-start">
        <small
          style={{ fontSize: 22 }}
          className="primary-text-regular mini-hide pt-8 pb-4 border-b border-gray-400">
          Profile Settings
        </small>

        <div className="pt-4">
          <StepSelection step={provider.step} setStep={onChange} />
        </div>
      </div>

      <div className="w-full md:w-2/5 md:ml-12 shadow-md mt-4 rounded-lg overflow-hidden">
        <div className="bg-white h-full overflow-scroll">
          {!provider.loader ? (
            <form onSubmit={e => onSubmit(e)} className="flex flex-col px-5">
              <div className="flex justify-between items-center border-b border-gray-300 py-4">
                <small style={{ fontSize: 19 }} className="primary-text-regular mini-hide">
                  {provider.step === 1
                    ? 'Player Info'
                    : provider.step === 2
                      ? 'Team Info'
                      : 'Social Info'}
                </small>

                <button
                  disabled={provider.submitLoader}
                  className={`primary-dark-button w-24 ${provider.socialError !== '' && 'cursor-not-allowed'
                    }`}
                  type="submit">
                  Update
                  {provider.submitLoader && (
                    <div className="ml-2">
                      <Loader small color="black" />
                    </div>
                  )}
                </button>
              </div>

              <div>
                {provider.step === 1 && (
                  <AccountInfo
                    provider={provider}
                    currentUser={currentUser}
                    onChange={onChange}
                    onImageChange={(image, imageFile) => {
                      dispatch({ field: 'img', value: image });
                      dispatch({ field: 'imgFile', value: imageFile });
                    }}
                    validatePhone={validatePhone}
                  />
                )}

                {provider.step === 2 && (
                  <PlayerInfo
                    sports={sports}
                    provider={provider}
                    dispatch={dispatch}
                    currentUser={currentUser}
                    onChange={onChange}
                    getPositionLabels
                    getSchoolsBySearch={getSchoolsBySearch}
                    getCollegesBySearch={getCollegesBySearch}
                    getSportPositions={getSportPositions}
                    resetPositions={resetPositions}
                    sportPositions={getPositionLabels(sportPositions)}
                  />
                )}

                {provider.step === 3 && (
                  <SocialInfo provider={provider} currentUser={currentUser} onChange={onChange} />
                )}
              </div>
            </form>
          ) : (
            <div className="loader-container">
              <Loader />
            </div>
          )}
        </div>

        <ChangePassword
          visible={provider.changePassModal}
          loader={provider.loader}
          oldPass={provider.oldPass}
          password={provider.password}
          confirmPass={provider.confirmPass}
          setModal={value => onChange('changePassModal', value)}
          setOldPass={value => onChange('oldPass', value)}
          setPassword={value => onChange('password', value)}
          setConfirmPass={value => onChange('confirmPass', value)}
          submit={e => submit(e)}
        />
      </div>
    </div>
  );
}

export default observer(UserProfile);
